import { gql } from '@apollo/client';
import client from '../../config/apollo-client-gana';

export const queriesLogin = {
    activateAccount: gql`
        mutation activarCuenta($token: String!) {
            activarCuenta(token: $token)
        }
    `,
    tryAgain: gql`
        mutation reenvioActivacionNewExperience($token: String!) {
            reenvioActivacionNewExperience(token: $token)
        }
    `
};

export const activeServices = {
    activateAccount: (token: string) => {
        return client.mutate({
            mutation: queriesLogin.activateAccount,
            variables: { token }
        });
    },

    tryAgain: (token: string) => {
        return client.mutate({
            mutation: queriesLogin.tryAgain,
            variables: { token }
        });
    }
};
