import React, { useEffect } from 'react';
import { activeServices } from '@services/account';
import { setData } from '@utils/transferData';
import { Layout, SEO } from '@componentsShared';
import siteUrl from '@utils/siteUrl';

type TProps = {
    token: string;
};

const ValidateAccount = ({ token }: TProps) => {
    const dataSuccess = {
        msg: "Se reenvió el link para la activación de la cuenta.",
        hasError: false
    }

    const dataError = {
        msg: "El link de activación ya no es valido por favor revise su correo.",
        hasError: true
    }

    const homeUrl = siteUrl + "/";

    const reenviarCorreo = () => {
        activeServices
            .tryAgain(token).then(response => {
                console.log(response);
                setData(dataSuccess, 'MsgResendLinkActivation');
                window.location.href = homeUrl
            }).catch(err => {
                setData(dataError, 'MsgResendLinkActivation');
                window.location.href = homeUrl
                console.error(err);
            });
    }

    const validateAccount = async () => {
        await activeServices
            .activateAccount(token)
            .then((response) => {
                console.log(response);
                window.location.href = siteUrl + "/registro/confirmado"
            })
            .catch((err) => {
                console.log(err);
                reenviarCorreo();
            });
    };
    useEffect(() => {
        validateAccount();
    }, []);

    return (
        <Layout>
            <SEO
                slug={siteUrl + '/registro/confirmar/'}
                title="Confirmando cuenta"
                description="Se esta confirmando la cuenta."
            />
        </Layout>
    )
};

export default ValidateAccount;
